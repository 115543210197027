import { Component, createRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExecutionEnvironment from 'exenv';

import { NEWLY_REGISTERED_COOKIE } from 'constants/cookies';
import { handleSignUpModalSubmit, initSignUpModal, setSignUpModal } from 'actions/headerfooter';
import Link from 'components/hf/HFLink';
import { CHECKOUT_URL_RE } from 'common/regex';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import LatencyTracking from 'helpers/LatencyTracking';
import { stripSpecialCharsDashReplace as strip } from 'helpers';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import SignUpModal from 'components/hf/SignUpModal';
import marketplace from 'cfg/marketplace.json';
import { toggleHeartingLoginModal } from 'actions/hearts';
import HeartLoginPrompt from 'components/common/HeartLoginPrompt';
import HtmlToReact from 'components/common/HtmlToReact';
import FooterAds from 'components/hf/6pm/FooterAds';
import { onEvent } from 'helpers/EventHelpers';

import css from 'styles/containers/hf/6pm/footer.scss';
const { hasHearting, vocUrl } = marketplace;

const CURRENT_YEAR = new Date().getFullYear();
export class Footer extends Component {
  static displayName = 'Footer';

  constructor(props) {
    super(props);
    this.footer = createRef();
  }

  componentDidMount() {
    const { isRemote, latencyTracking } = this.props;

    if (isRemote && !this.rootSelector) {
      /*
        "Hydrating" React Portals isn't yet supported so we get a console
        warning when serving the remote HF in dev envs.
        But worry not, nothing is actually wrong.
        https://github.com/facebook/react/issues/13097
        https://github.com/facebook/react/issues/12615
      */
      this.rootSelector = document.getElementById('martyRemoteFooter');
      this.rootSelector.innerHTML = '';
      this.forceUpdate();
    }

    this.signUpModalCheck();

    // Setup latencyTracking for init page load
    // Use latencyTracking prop for testing
    this.latencyTracking = latencyTracking || new LatencyTracking();
    const footerEl = this.footer.current;
    if (footerEl) {
      onEvent(footerEl, 'click', this.handlePageContentClickEvents, null, this);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname: prevPath }
    } = prevProps;
    const {
      location: { pathname }
    } = this.props;
    const hasLocationChanged = prevPath !== pathname;

    this.signUpModalCheck();

    if (hasLocationChanged) {
      this.latencyTracking.triggerSoftNavigation(); // Fire latency tracking for client routing
      if (typeof window !== 'undefined') {
        window?.canaryMetrics?.triggerSoftNavigation();
      }
    }
  }

  handlePageContentClickEvents = evt => {
    if (evt.target.matches('[data-footer-event] a')) {
      trackEvent('TE_FOOTER_NAV', `footerbottom:${evt.target.innerText}`);
    }
  };

  signUpModalCheck = () => {
    const {
      location: { pathname },
      isNewlyRegistered,
      initSignUpModal
    } = this.props;
    if (isNewlyRegistered && !pathname.match(CHECKOUT_URL_RE)) {
      initSignUpModal();
    }
  };

  makeFooterNav = data => {
    const { testId } = this.context;
    const heading = data?.heading?.text;
    const { footerMenu } = data;
    return (
      <>
        {heading && <h3>{heading}</h3>}
        <ul>
          {footerMenu &&
            footerMenu.map(({ text, link, gae }) => (
              <li key={text}>
                <Link
                  data-test-id={testId(text)}
                  to={link}
                  onClick={() => {
                    trackEvent('TE_FOOTER_NAV', `${heading}:${gae || text}`);
                    trackLegacyEvent('Footer', strip(heading), strip(gae || text));
                  }}
                >
                  {text}
                </Link>
              </li>
            ))}
        </ul>
      </>
    );
  };

  render() {
    const {
      copyright,
      bottomLinks,
      socialLinks,
      feedbackLink,
      isRemote,
      content,
      isSignUpModalOpen,
      setSignUpModal,
      handleSignUpModalSubmit,
      forceRender,
      toggleHeartingLoginModal = () => {},
      heartLoginPrompt = { isOpen: false },
      router,
      ad
    } = this.props;
    const { testId } = this.context;
    const {
      'sign-up-modal': dataModal,
      'footermenu-1': data1,
      'footermenu-2': data2,
      'footermenu-3': data3,
      'footermenu-4': data4,
      'footermenu-5': data5
    } = content?.Footer?.slotData || {};

    const footer = (
      <>
        <FooterAds ad={ad} />
        <footer className={css.footerBg} role="contentinfo" ref={this.footer} data-test-id={testId('footerElement')}>
          <h2 className={'sr-only'} data-test-id={testId('footerHeading')}>
            6pm Footer
          </h2>
          <div className={css.footerTopLinks}>
            {data1 && <div>{this.makeFooterNav(data1)}</div>}
            {data2 && <div>{this.makeFooterNav(data2)}</div>}
            {data3 && <div>{this.makeFooterNav(data3)}</div>}
            <div>
              {data4 && this.makeFooterNav(data4)}
              {!!socialLinks && (
                <>
                  <p>{socialLinks.heading.text}</p>
                  <ul className={css.footerSocial}>
                    {socialLinks.footerMenu?.map(({ link, text, gae }) => (
                      <li key={text} data-test-id={testId('socialMediaSite')}>
                        <a
                          data-test-id={testId(text)}
                          href={link}
                          rel="noopener noreferrer"
                          target="_blank"
                          onClick={() => {
                            trackEvent('TE_FOOTER_SOCIAL', strip(gae || text));
                            trackLegacyEvent('Global', 'Footer', strip(gae || text));
                          }}
                        >
                          {text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <div>
              {data5 && this.makeFooterNav(data5)}
              {!!feedbackLink && (
                <a
                  className={css.footerFeedback}
                  data-test-id={testId('footerSurvey')}
                  onClick={() => {
                    trackEvent('TE_FOOTER_FEEDBACK');
                    trackLegacyEvent('Global', 'Footer', 'Feedback');
                  }}
                  href={`${vocUrl}?source=footer`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <HtmlToReact noContainer={true}>{feedbackLink.pageContent?.body}</HtmlToReact>
                </a>
              )}
            </div>
          </div>
          {!!bottomLinks && (
            <ul className={css.footerLegalLinks}>
              {bottomLinks.footerMenu?.map(({ link, text, gae }) => (
                <li key={text}>
                  <Link
                    data-test-id={testId(text)}
                    to={link}
                    onClick={() => {
                      trackEvent('TE_FOOTER_POLICIES', strip(gae || text));
                      trackLegacyEvent('Global', 'Footer', strip(gae || text));
                    }}
                  >
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <div className={css.footerIconCards}>We accept Visa, Mastercard, Discover, and American Express</div>
          <HtmlToReact className={css.footerBotCopy} data-footer-event data-test-id={testId('footerLegalCopy')}>
            {copyright?.pageContent?.body.replace(/{{currentYear}}/g, CURRENT_YEAR)}
          </HtmlToReact>
          <SignUpModal
            data={dataModal}
            isSignUpModalOpen={isSignUpModalOpen}
            setSignUpModal={setSignUpModal}
            handleSignUpModalSubmit={handleSignUpModalSubmit}
          />
          {hasHearting && (
            <HeartLoginPrompt isOpen={heartLoginPrompt.isOpen} id={heartLoginPrompt.id} toggleModal={toggleHeartingLoginModal} router={router} />
          )}
        </footer>
      </>
    );

    if (isRemote && ExecutionEnvironment.canUseDOM && this.rootSelector) {
      return createPortal(footer, this.rootSelector);
    } else if (!isRemote || forceRender) {
      return footer;
    }
    return null;
  }
}

// Placeholder just so we can run two servers at once
function mapStateToProps(state) {
  const { content } = state.headerFooter;
  return {
    content,
    isSignUpModalOpen: state.headerFooter.isSignUpModalOpen,
    isNewlyRegistered: !!state.cookies[NEWLY_REGISTERED_COOKIE],
    isRemote: state.headerFooter.isRemote,
    location: state.router.location,
    heartLoginPrompt: state.hearts.heartLoginPrompt,
    copyright: state.headerFooter.content.Footer.slotData['copyright'],
    bottomLinks: state.headerFooter.content.Footer.slotData['legal-links'],
    socialLinks: state.headerFooter.content.Footer.slotData['social-links'],
    feedbackLink: state.headerFooter.content.Footer.slotData['customer-feedback-survey'],
    ad: content.Footer.slotData.ad
  };
}

Footer.contextTypes = {
  testId: PropTypes.func
};

const FooterConnected = connect(mapStateToProps, {
  initSignUpModal,
  setSignUpModal,
  handleSignUpModalSubmit,
  toggleHeartingLoginModal
})(Footer);

const FooterConnectedWithErrorBoundary = withErrorBoundary('Footer', FooterConnected);
export default FooterConnectedWithErrorBoundary;
